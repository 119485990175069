import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import KbItemPreview from '../components/KbItemPreview'
import styled from 'styled-components'

const Items = styled.div`
  margin-top: 3rem;
  line-height: 1.5;
`

export default function Articles({ data, location }) {
  const siteTitle = data.site.siteMetadata.title
  const items = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Knowledge base"
        keywords={[`blog`, `python`, `javascript`, `react`]}
      />
      <section>
        <h1>My personal knowledge base</h1>
        <Items>
          {items.map(({ node }) => (
            <KbItemPreview post={node} key={node.frontmatter.slug} />
          ))}
        </Items>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: {
        fields: { collection: { eq: "kb" } }
        frontmatter: { published: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            slug
          }
        }
      }
    }
  }
`
