import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PostTags from './PostTags'

const Container = styled.div`
  margin-bottom: 3rem;

  .excerpt {
    margin-top: 0;
  }

  h2 {
    display: inline-block;
    line-height: 1.2;
  }
`

const Title = styled.h2`
  margin: 0 0 0.5rem;

  @media (max-width: 650px) {
    font-size: 26px;
  }

  a {
    box-shadow: none;
  }

  a:hover {
    color: ${props => props.theme.linkColor};
  }
`

const Excerpt = styled.p`
  display: inline-block;
`

const ReadLink = styled(Link)`
  color: ${props => props.theme.linkColor};
  text-decoration: none;
  padding: 0 1px;
`

export default function PostPreview({ post }) {
  const title = post.frontmatter.title || post.frontmatter.slug
  const { tags } = post.frontmatter
  const { excerpt } = post

  return (
    <Container>
      <Title>
        <Link to={post.frontmatter.slug}>{title}</Link>
      </Title>
      <PostTags tags={tags} />
      {excerpt && (
        <React.Fragment>
          <Excerpt>
            {excerpt} <ReadLink to={post.frontmatter.slug}>Read</ReadLink>
          </Excerpt>
        </React.Fragment>
      )}
    </Container>
  )
}
